module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kibala","short_name":"Kibala","start_url":"/","background_color":"#000","theme_color":"#fff","display":"minimal-ui","icon":"src/img/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f11a8b9e8b6ff0569b2392d09a3e1599"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
